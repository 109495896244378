@import url(https://fonts.googleapis.com/css2?family=Lobster);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.table.selectable tr {
  cursor: pointer
}

.leaflet-container {
  z-index: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}


.ui.center.aligned.header {font-size: 1.3rem;}
@media screen and (max-width: 900px) {
    .ui.center.aligned.header {font-size: 1.1rem;}
}

.leaflet-marker-icon.paused {opacity: 0.5}
.ui.menu-desktop {display: block;}
.ui.menu-mobile {display: none;}

@media screen and (max-width: 900px) {
    .ui.menu-desktop {display: none;}
    .ui.menu-mobile {display: block; margin-top: 0}
}
